<template>
	<div>Help</div>
</template>

<script>
export default {
	props: {},
	data() {
		return {}
	},
	created() {}
}
</script>

<style scoped lang="scss"></style>
